import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";
import { resetVerifyCommandProducts } from "../reducer/picking.reducer";
import {
  closeGlobalToast,
  setGlobalToast,
} from "../reducer/globalComponents.reducer";

import { setLastSavedVerifiedCommands } from "../reducer/general.reducer";
import { VerifyCommandProductsListInterface } from "../interfaces/picking.interface";

const handleSetGlobalToast = (
  dispatch: any,
  message?: string,
  severity = "success",
) => {
  if (message) {
    dispatch(setGlobalToast({ message, severity }));
  } else {
    dispatch(closeGlobalToast());
  }
};

export const getVerifyCommandProducts = createAsyncThunk(
  "/picking/getVerifyCommandProducts",
  async (cellScanned: string | number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(
        `/detaliiPickingOrderVerificare/${cellScanned}`,
        "GET",
      );

      return data;
    } catch (err: any) {
      dispatch(resetVerifyCommandProducts());

      console.warn("/picking/getVerifyCommandProducts", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPackingBoxes = createAsyncThunk(
  "/picking/getPackingBoxes",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest("/nomCutiiAmbalare", "GET");
      return data;
    } catch (err: any) {
      console.warn("/picking/getPackingBoxes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postValidateVerification = createAsyncThunk(
  "/picking/valideazaVerificare",
  async (
    {
      params,
    }: {
      params: {
        cellScanned: string | number;
        cutii: any[];
        commandNumber?: string | number | null;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/valideazaVerificare", "POST", params);

      const commandNumber = params?.commandNumber?.toString();

      if (commandNumber) {
        dispatch(setLastSavedVerifiedCommands(commandNumber));
      }

      return data;
    } catch (err: any) {
      console.warn("/picking/valideazaVerificare", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPrintCommandInvoice = createAsyncThunk(
  "/picking/getPrintCommandInvoice",
  async (commandId: string | number, { rejectWithValue, dispatch }) => {
    try {
      await apiRequest(`/facturiComanda/${commandId}`, "GET");
      handleSetGlobalToast(dispatch, "Factura a fost printata!", "success");
    } catch (err: any) {
      console.warn("/picking/getPrintCommandInvoice", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPrintCommandInvoiceFromHistory = createAsyncThunk(
  "/picking/getPrintCommandInvoiceFromHistory",
  async (commandId: string | number, { rejectWithValue, dispatch }) => {
    try {
      await apiRequest(`/facturiComandaIstoric/${commandId}`, "GET");
      handleSetGlobalToast(dispatch, "Factura a fost printata!", "success");
    } catch (err: any) {
      console.warn("/picking/getPrintCommandInvoiceFromHistory", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getListaCutiiComanda = createAsyncThunk(
  "/picking/listaCutiiComanda",
  async (commandId: string | number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(
        `/listaCutiiComanda/${commandId}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/picking/listaCutiiComanda", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getValidatePacking = createAsyncThunk(
  "/picking/valideazaAmbalare",
  async (
    {
      params,
      callBack,
    }: { params: { cellScanned: string; op: string }; callBack: () => void },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await apiRequest(`/valideazaAmbalare`, "POST", {
        ...params,
      });
      callBack();
    } catch (err: any) {
      console.warn("/picking/valideazaAmbalare", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getDeliveryArrangement = createAsyncThunk(
  "/picking/deliveryArrangement",
  async (
    params: {
      page: number;
      perPage: number;
      status: string;
      facturi?: string;
      ruta?: string;
      deliveryPoint?: string;
      commandNumber?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const {
      page,
      perPage,
      status,
      deliveryPoint,
      facturi,
      ruta,
      commandNumber,
    } = params;
    try {
      const { data } = await apiRequest(
        `/pickingOrderWeb?page=${page}&per_page=${perPage}&status=${status}&facturi=${facturi}&ruta=${ruta}&deliveryPoint=${deliveryPoint}&commandNumber=${commandNumber}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/picking/deliveryArrangement", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getActivateDeliveryArrangement = createAsyncThunk(
  "/picking/activeazaDispozitieLivrare",
  async (
    {
      params,
      callBack,
    }: {
      params: {
        op: string;
        page: number;
        perPage: number;
        status: string;
        facturi?: string;
        ruta?: string;
        deliveryPoint?: string;
      };
      callBack: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { op, status } = params;
    try {
      await apiRequest(
        `/activeazaDispozitieLivrare?op=${op}&status=${status}`,
        "GET",
      );
      getDeliveryArrangement(params);
      callBack();
    } catch (err: any) {
      console.warn("/picking/activeazaDispozitieLivrare", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postValidateQuantityVerification = createAsyncThunk(
  "/picking/valideazaVerificareCantitate",
  async (
    {
      params,
      callBack,
    }: {
      params: {
        quantity?: number | string | null;
        commandCode: string;
        scannedCode?: string;
        productId: string;
        type: string;
        product: VerifyCommandProductsListInterface;
        productsAdded?: {
          lot: string | number | null;
          data_exp: Date | null;
          cantitate: string | number | null;
        }[];
        totalQuantity?: number;
      };
      callBack: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await apiRequest("/valideazaVerificareCantitate", "POST", params);
      dispatch(getVerifyCommandProducts(params.commandCode));
      callBack();
    } catch (err: any) {
      console.warn("/picking/valideazaVerificareCantitate", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getCorrectVerification = createAsyncThunk(
  "/picking/correctVerification",
  async (
    {
      params,
      callBack,
    }: { params: { op: string | number }; callBack?: () => void },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await apiRequest(`/corectieComanda/${params.op}`, "GET");
      callBack && callBack();
    } catch (err: any) {
      console.warn("/picking/correctVerification", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getPrintAWBPackingBoxes = createAsyncThunk(
  "/picking/getPrintAWBPackingBoxes",
  async (
    {
      params,
      callBack,
    }: {
      params: {
        commandId: string | number;
      };
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { commandId } = params;
    try {
      const { data } = await apiRequest(
        `/listaCutiiComanda/${commandId}`,
        "GET",
      );
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/picking/getPrintAWBPackingBoxes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postValidateAWBVerification = createAsyncThunk(
  "/picking/rePrintAwb",
  async (
    {
      params,
      callback,
    }: {
      params: {
        op: string | number;
        cutii: any[];
      };
      callback: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`/rePrintAwb`, "POST", params);
      callback && callback();
      dispatch(resetVerifyCommandProducts());
      return data;
    } catch (err: any) {
      console.warn("/picking/rePrintAwb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReprintAWB = createAsyncThunk(
  "/picking/getReprintAWB",
  async (
    {
      params,
      callBack,
    }: {
      params: {
        idNod: string | number;
      };
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idNod } = params;
    try {
      const { data } = await apiRequest(`/generateBarcode/${idNod}`, "GET");
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/picking/getReprintAWB", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getDeliveryInvoices = createAsyncThunk(
  "/picking/getDeliveryInvoices",
  async (
    {
      params,
    }: {
      params: {
        op: string | number;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    const { op } = params;
    try {
      const { data } = await apiRequest(
        `/facturiDispozitieLivrare/${op}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/picking/getDeliveryInvoices", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postSplitDeliveryArrangements = createAsyncThunk(
  "/picking/postSplitDeliveryArrangements",
  async (
    {
      params,
      callBack,
    }: {
      params: {
        op: string | number;
        facturi: string[];
        type: "split" | "disable";
        valueZone: { id: number | string; label: string | number } | null;
      };
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/splitDispozitieLivrare",
        "POST",
        params,
      );
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/picking/postSplitDeliveryArrangements", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchVerificationZones = createAsyncThunk(
  "/picking/fetchVerificationZones",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest("/listaZoneVerificare", "GET");
      return data;
    } catch (err: any) {
      console.warn("/picking/fetchVerificationZones", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUnlockZone = createAsyncThunk(
  "/picking/deblocareZona",
  async (
    {
      idZona,
    }: {
      idZona: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`/deblocareZona/${idZona}`, "GET");
      dispatch(fetchVerificationZones());
      return data;
    } catch (err: any) {
      console.warn("/picking/deblocareZona", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchCommandHistory = createAsyncThunk(
  "/picking/fetchCommandHistory",
  async (
    params: {
      page: number;
      per_page: number;
      facturi?: string;
      ruta?: string;
      deliveryPoint?: string;
      commandNumber?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/istoricComanda", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/picking/fetchCommandHistory", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateOrUpdateVerificationZone = createAsyncThunk(
  "/picking/postCreateOrUpdateVerificationZone",
  async (
    {
      params,
      callBack,
    }: {
      params: any;
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/createOrUpdateVerificationZone",
        "POST",
        params,
      );
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/picking/postCreateOrUpdateVerificationZone", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchDeleteVerificationZone = createAsyncThunk(
  "/picking/deleteVerificationZone",
  async (
    {
      idZona,
    }: {
      idZona: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        `/deleteVerificationZone/${idZona}`,
        "GET",
      );
      dispatch(fetchVerificationZones());
      return data;
    } catch (err: any) {
      console.warn("/picking/deleteVerificationZone", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getSelectVerificationZone = createAsyncThunk(
  "/inventory/getSelectVerificationZone",
  async (idZona: number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(
        `/selectVerificationZone/${idZona}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/inventory/getSelectVerificationZone", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postChangeClient = createAsyncThunk(
  "/picking/postChangeClient",
  async (
    params: {
      op: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/changeClient", "POST", params);

      return data;
    } catch (err: any) {
      console.warn("/picking/postChangeClient", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
