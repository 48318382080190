import React from "react";
import CustomButton from "../../../../components/CustomButton";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import { BorderouHistoryListInterface } from "../../../../redux/interfaces/expedition.interface";

import { useAppDispatch } from "../../../../redux/hooks";
import { getDownloadBorderou } from "../../../../redux/api/expedition.api";

function BorderouHistoryTableActionsButtons({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: BorderouHistoryListInterface;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();

  const handleClickDownloadButton = () => {
    if (!crudData?.nod) {
      return "";
    }

    dispatch(
      getDownloadBorderou({
        idBorderou: crudData.idBorderou,
        nod: crudData?.nod,
      }),
    );

    return;
  };

  return (
    <div>
      {crudData?.nod && (
        <CustomButton
          variant="contained"
          className="mx-1 mb-1"
          onClick={handleClickDownloadButton}>
          Descarca borderou
        </CustomButton>
      )}
    </div>
  );
}

export default BorderouHistoryTableActionsButtons;
