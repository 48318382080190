import { memo } from "react";
import { useNavigate } from "react-router-dom";

import { TableItemId } from "../../../../components/tables/TableWidget";
import CustomButton from "../../../../components/CustomButton";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import {
  getGenerateBarcodeNodeDetails,
  getGenerateBorderouNodeDetails,
  getPreviewBorderou,
} from "../../../../redux/api/expedition.api";
import { useAppDispatch } from "../../../../redux/hooks";
import { expeditionRoutes } from "../../../../router/routesConstants";
import SvgIcon from "../../../../helpers/SvgIcon";

function NodesRouteTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const generateBarcode = () => {
    dispatch(getGenerateBarcodeNodeDetails({ idNode: itemId }));
  };

  const generateBorderou = () => {
    dispatch(getGenerateBorderouNodeDetails({ nodeName: crudData.nodNume }));
  };

  const handleEditNod = () => {
    navigate(
      `/${expeditionRoutes.editNodesRoutes}?driver=${crudData.sofer}&car=${crudData.auto}&nodId=${itemId}`,
    );
    return;
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        tooltipTitle="Editeaza sofer/masina"
        className="mx-1 mb-1"
        onClick={handleEditNod}>
        <SvgIcon type="EDIT" />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Genereaza eticheta"
        className="mx-1 mb-1"
        withConfirmationModal={{
          modalTitle: `Genereaza eticheta pentru nodul ${crudData.nodNume}`,
          modalLeftButtonColor: "inherit",
          modalRightButtonOnClick: generateBarcode,
        }}>
        <SvgIcon type="LABEL" />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Genereaza borderou"
        className="mx-1 mb-1"
        withConfirmationModal={{
          modalTitle: `Genereaza borderou pentru nodul ${crudData.nodNume}`,
          modalLeftButtonColor: "inherit",
          modalRightButtonOnClick: generateBorderou,
          modalLeftButtonOnClick: () => getPreviewBorderou(crudData.nodNume),
          modalRightButtonText: "Genereaza",
          modalLeftButtonText: "Preview",
        }}>
        <SvgIcon type="SUMMARIZE" />
      </CustomButton>
    </div>
  );
}

export default memo(NodesRouteTableActionButtons);
