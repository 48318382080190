import { useEffect, useState } from "react";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import {
  tableLocalCategorySearch,
  tableLocalGlobalSearch,
} from "../../components/tables/tableHelper";
import TableWidget from "../../components/tables/TableWidget";
import useRoutesHelper from "../../hooks/useRoutesHelper";
import { getReceiptsDataList } from "../../redux/api/receipt.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  clearAvailableReceiptsList,
  clearProductDetails,
} from "../../redux/reducer/receipt.reducer";
import objectParsers from "../../utils/objectParsers";
import ReceivedProductsTableAction from "./components/TableActionsButtonsComponents/ReceivedProductsTableAction";
import {
  receiptDetailsTableHeader,
  receivedProductsTableConfig,
} from "./components/tableConfigs/receivedProductsTableConfig";

function ReceivedProducts() {
  const dispatch = useAppDispatch();
  const { routeParams } = useRoutesHelper();
  const receptionId = routeParams?.reception;
  const receivedAt = routeParams?.data;

  const {
    receipt: {
      receiptDetails: {
        data: { products, furnizor, status },
        isLoadingReceiptData,
      },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const [tableData, setTableData] = useState<any>([]);

  useEffect(() => {
    if (receptionId) {
      dispatch(getReceiptsDataList(receptionId));
    }
  }, [dispatch, receptionId]);

  useEffect(() => {
    if (products) {
      setTableData(products);
    }
  }, [products]);

  useEffect(() => {
    return () => {
      dispatch(clearAvailableReceiptsList());
      dispatch(clearProductDetails());
    };
  }, [dispatch]);

  const handleTableActions = (tableActions: TableActions) => {
    if (tableActions.tableGlobalSearch?.length > 0) {
      const filterProductsData = products.filter((product: any) => {
        const showCurrentItem = tableLocalGlobalSearch(
          [
            product.numeProdusErp,
            product.codProdusErp,
            product.lot,
            product.sn,
          ],
          tableActions.tableGlobalSearch,
        );
        return showCurrentItem;
      });
      setTableData(filterProductsData);
      return;
    }

    if (objectParsers.hasValue(tableActions.tableSearch)) {
      const filteredProducts = tableLocalCategorySearch(
        products,
        tableActions.tableSearch,
      );
      setTableData(filteredProducts);
      return;
    }
    setTableData(products);
  };

  const getPageTitle = () => {
    if (isLoadingReceiptData) {
      return "Se încarcă datele...";
    }
    return `Produse receptionate de la ${furnizor} in data ${receivedAt}`;
  };

  return (
    <TableWidget
      tableTitle={getPageTitle()}
      tableHeaderData={receiptDetailsTableHeader}
      tableItemsData={receivedProductsTableConfig(tableData)}
      borderedRow
      withTablePagination={false}
      ActionButtonsComponent={(props: any) => (
        <ReceivedProductsTableAction {...props} status={status} />
      )}
      handleTableActions={handleTableActions}
    />
  );
}

export default ReceivedProducts;
