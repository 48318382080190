import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import Text from "../styleguide/Text";
import Colors from "../theme/Colors";
import { getFirstLetterUpperCase } from "../utils/parsers";
import { initialMainRoute } from "./routesConstants";

function BreadcrumbsComponent() {
  const location = useLocation();
  const currentPath = location.pathname;
  const searchParams = location.search; // Retain query parameters

  const currentPathArray = currentPath.split("/").slice(1); // Remove empty first element
  const filteredDisplayPath = currentPathArray.filter((segment) =>
    isNaN(Number(segment)),
  ); // Remove numbers for display

  if (!currentPathArray.includes(initialMainRoute)) {
    filteredDisplayPath.unshift(initialMainRoute);
  }

  if (filteredDisplayPath.length === 0) return null;

  return (
    <div className="bg-white py-5 px-7">
      <Breadcrumbs maxItems={5} aria-label="breadcrumb">
        {filteredDisplayPath.map((path, idx) => {
          // Get the original full path including numbers
          const fullPathBeforeCurrent = currentPathArray.slice(
            0,
            currentPathArray.indexOf(path) + 1,
          );
          const constructedPath = `/${fullPathBeforeCurrent.join(
            "/",
          )}${searchParams}`;

          // Last item (active breadcrumb)
          if (idx === filteredDisplayPath.length - 1) {
            return (
              <Text key={idx} color={Colors.black}>
                {getFirstLetterUpperCase(path)}
              </Text>
            );
          }

          return (
            <Link key={idx} to={constructedPath} className="text-muted">
              {getFirstLetterUpperCase(path)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}

export default BreadcrumbsComponent;
