import { memo } from "react";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { receptionRoutes } from "../../../../router/routesConstants";
import CustomButton from "../../../../components/CustomButton";

import { KTSVG } from "../../../../helpers/KTSVG";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";

function PutawayTableActionButtons({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}) {
  const handleputawayOperations = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.transferReceived}/${receptionRoutes.splitOperations}?reception=${crudData.idFluxReceptie}`;
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        tooltipTitle="Transfer"
        className="mx-1 mb-1"
        navigate={handleputawayOperations()}>
        <KTSVG
          path="/media/svg/reception/document.svg"
          className="svg-icon-1"
          fill="white"
        />
      </CustomButton>
    </div>
  );
}

export default memo(PutawayTableActionButtons);
