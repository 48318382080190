import React, { useState } from "react";
import { useAppDispatch } from "../../../../../redux/hooks";
import CustomButton from "../../../../../components/CustomButton";
import {
  getActivateDeliveryArrangement,
  getDeliveryArrangement,
  postChangeClient,
} from "../../../../../redux/api/picking.api";
import { getSyncronizeInvoices } from "../../../../../redux/api/external.api";
import { pickingRoutes } from "../../../../../router/routesConstants";
import SvgIcon from "../../../../../helpers/SvgIcon";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../../helpers/apiHelper";

function DeliveryArrangementActionButton(props: any) {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const showSplitButton =
    props.crudData.status === 0 || props.crudData.status === 1;

  const handleActivateDeliveryArrangement = () => {
    setIsLoading(true);

    dispatch(
      getActivateDeliveryArrangement({
        params: {
          op: props.crudData.Op,
          page: props.tableContext.tablePagination.page,
          perPage: props.tableContext.tablePagination.rowsPerPage,
          status: props.tableContext.tableFilter?.status ?? 0,
          facturi: props.tableContext.tableFilter?.facturi,
          ruta: props.tableContext.tableFilter?.ruta,
          deliveryPoint: props.tableContext.tableFilter?.deliveryPoint,
        },
        callBack: () => {
          dispatch(
            getDeliveryArrangement({
              page: props.tableContext.tablePagination.page,
              perPage: props.tableContext.tablePagination.rowsPerPage,
              status: props.tableContext.tableFilter?.status ?? 0,
              facturi: props.tableContext.tableSearch?.facturi ?? "",
              ruta: props.tableContext.tableSearch?.ruta ?? "",
              deliveryPoint:
                props.tableContext.tableSearch?.deliveryPoint ?? "",
              commandNumber:
                props.tableContext.tableSearch?.commandNumber ?? "",
            }),
          );
          setIsLoading(false);
        },
      }),
    );
  };

  const handleSyncronizeInvoices = () => {
    dispatch(
      getSyncronizeInvoices({
        op: props.crudData.Op,
      }),
    );
  };

  const handleChangeDeliveryPoint = async () => {
    if (!props.crudData.Op) {
      return;
    }

    setIsLoading(true);

    const resultAction = await dispatch(
      postChangeClient({
        op: props.crudData.Op,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postChangeClient) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postChangeClient) ===
      endpointStatus.fulfilled
    ) {
      setIsLoading(false);

      dispatch(
        getDeliveryArrangement({
          page: props.tableContext.tablePagination.page,
          perPage: props.tableContext.tablePagination.rowsPerPage,
          status: props.tableContext.tableFilter?.status ?? 0,
          facturi: props.tableContext.tableSearch?.facturi ?? "",
          ruta: props.tableContext.tableSearch?.ruta ?? "",
          deliveryPoint: props.tableContext.tableSearch?.deliveryPoint ?? "",
          commandNumber: props.tableContext.tableSearch?.commandNumber ?? "",
        }),
      );
    }
  };

  return (
    <div>
      {props.crudData.status <= 1 && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          loading={isLoading}
          color={props.crudData.status === 0 ? "success" : "warning"}
          tooltipTitle={`${
            props.crudData.status === 0 ? "Activati" : "Dezactivati"
          }`}
          withConfirmationModal={{
            modalLeftButtonVariant: "outlined",
            modalTitle: `Doriti sa ${
              props.crudData.status === 0 ? "activati" : "dezactivati"
            } comanda nr: ${props.crudData.id}?`,
            modalRightButtonOnClick: handleActivateDeliveryArrangement,
          }}>
          <SvgIcon type="ACTIVATE" fill="white" className="svg-icon-3" />
        </CustomButton>
      )}

      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        loading={isLoading}
        tooltipTitle="Resincronizati"
        withConfirmationModal={{
          modalLeftButtonVariant: "outlined",
          modalTitle: `Doriti sa resincronizati facturile din comanda nr: ${props.crudData.id}?`,
          modalRightButtonOnClick: handleSyncronizeInvoices,
        }}>
        <SvgIcon type="RESYNC" fill="white" className="svg-icon-3" />
      </CustomButton>

      {showSplitButton && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          loading={isLoading}
          tooltipTitle="Split"
          navigate={`/${pickingRoutes.picking}/${pickingRoutes.splitInvoices}?delivery=${props.crudData.pctDeLivr}&op=${props.crudData.Op}`}>
          <SvgIcon type="SPLIT_LIST" />
        </CustomButton>
      )}

      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        loading={isLoading}
        tooltipTitle="Schimbare punct de livrare"
        withConfirmationModal={{
          modalLeftButtonVariant: "outlined",
          modalTitle: `Doriti sa schimbati punctul de livrare al clientului pentru comanda nr: ${props.crudData.Op}?`,
          modalRightButtonOnClick: handleChangeDeliveryPoint,
        }}>
        <SvgIcon type="CHANGE_ROUTE" fill="white" className="svg-icon-3" />
      </CustomButton>
    </div>
  );
}

export default DeliveryArrangementActionButton;
