import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Divider, List, ListItem, ListItemButton } from "@mui/material";
import useAppMenuRoutes from "../useAppMenuRoutes";
import SvgIcon from "../../helpers/SvgIcon";
import Text from "../../styleguide/Text";

const DrawerItems = ({
  handleDrawerClose,
}: {
  handleDrawerClose?: () => void;
}) => {
  const navigate = useNavigate();
  const categories = useAppMenuRoutes();

  const handleNavigate = (path: string) => {
    if (handleDrawerClose) {
      handleDrawerClose();
    }
    navigate(path);
  };

  return (
    <div>
      {categories.map(({ title, categories }, index) => {
        return (
          <div key={index}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<SvgIcon type="EXPAND_MORE" />}
                aria-controls={`${index}-content`}
                id={`${index}-header`}>
                <div className="d-flex align-items-center">
                  <SvgIcon type="MENU" className="svg-icon-4" />
                  <Text variant="h6" className="fw-bolder  p-2">
                    {title}
                  </Text>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {categories?.map(({ title, path }, index) => {
                    return (
                      <ListItem
                        key={index}
                        disablePadding
                        onClick={() => handleNavigate(path)}>
                        <ListItemButton>
                          <SvgIcon
                            type="DOUBLE_ARROW_RIGHT"
                            className="svg-icon-5"
                          />
                          <Text variant="body1" className="fw-bolder p-1">
                            {title}
                          </Text>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Divider />
          </div>
        );
      })}
    </div>
  );
};

export default DrawerItems;
